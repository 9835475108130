import { useState } from 'react'
import ReCAPTCHA from 'react-google-recaptcha'

import {
    Button,
    Checkbox,
    FormGroup,
    TextField,
    IconButton,
    InputLabel,
    Typography,
    FormControl,
    OutlinedInput,
    InputAdornment,
    FormControlLabel,
} from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

import { AgreeWithTermsAndConditions } from '../../components/Shared'
import { FormikError } from '../../utils/formik/FormikError'
import { useTranslate } from '../../hooks/translate'
import { i18nRegister } from './i18nRegister'
import { texts } from '../../utils/i18n/texts'

function AgreeWithEmailSubsribtion(props: any): JSX.Element {
    const { label, main } = props
    return (
        <>
            {label}
            <Typography variant="body1" style={{ fontWeight: 600, display: 'inline' }}>
                {main}
            </Typography>
        </>
    )
}

export function RegisterForm(props: any): JSX.Element {
    const {
        values,
        errors,
        touched,
        handleBlur,
        handleSubmit,
        handleChange,
        setFieldValue,
    } = props

    const [showPasswords, setShowPasswords] = useState(false)

    const toggleShowPasswords = () => setShowPasswords((oldState) => !oldState)

    const handleCaptchaChange = (token: any) => setFieldValue('captchaToken', token)
    const handleCaptchaExpired = () => setFieldValue('captchaToken', '')

    const t = useTranslate(i18nRegister)

    return (
        <form onSubmit={handleSubmit} className="register-input-fields">
            <div className="register-input-field">
                <TextField
                    fullWidth
                    type="email"
                    name="email"
                    label={t.email}
                    variant="outlined"
                    onBlur={handleBlur}
                    value={values.email}
                    onChange={handleChange}
                />
                <FormikError name="email" errors={errors} touched={touched} />
            </div>
            <div className="register-input-field">
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="toggle-password-visibility">{t.password}</InputLabel>
                    <OutlinedInput
                        type={showPasswords ? 'text' : 'password'}
                        id="toggle-password-visibility"
                        onChange={handleChange}
                        value={values.password}
                        onBlur={handleBlur}
                        label={t.password}
                        name="password"
                        fullWidth
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    onClick={toggleShowPasswords}
                                    aria-label="toggle password visibility"
                                >
                                    {showPasswords ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormikError name="password" errors={errors} touched={touched} />
            </div>
            <div className="register-input-field">
                <FormControl fullWidth variant="outlined">
                    <InputLabel htmlFor="toggle-password-visibility2">{t.confirmPassword}</InputLabel>
                    <OutlinedInput
                        fullWidth
                        type={showPasswords ? 'text' : 'password'}
                        id="toggle-password-visibility2"
                        value={values.confirmPassword}
                        label={t.confirmPassword}
                        onChange={handleChange}
                        name="confirmPassword"
                        onBlur={handleBlur}
                        endAdornment={
                            <InputAdornment position="end">
                                <IconButton
                                    edge="end"
                                    onClick={toggleShowPasswords}
                                    aria-label="toggle password visibility"
                                >
                                    {showPasswords ? <VisibilityOff /> : <Visibility />}
                                </IconButton>
                            </InputAdornment>
                        }
                    />
                </FormControl>
                <FormikError name="confirmPassword" errors={errors} touched={touched} />
            </div>
            <div className="helpers-section">
                <FormGroup className="register-checkbox">
                    <FormControlLabel
                        onChange={handleChange}
                        name="subscribeNewsletter"
                        label={<AgreeWithEmailSubsribtion label={t.subscribe} main={t.subscribeTo} />}
                        control={<Checkbox color="primary" checked={values.subscribeNewsletter} />}
                    />
                </FormGroup>
                <FormGroup className="register-checkbox">
                    <FormControlLabel
                        name="agreeWithTC"
                        onChange={handleChange}
                        label={<AgreeWithTermsAndConditions label={t.agreeText} main={t.tcText} />}
                        control={<Checkbox color="primary" checked={values.agreeWithTC} />}
                    />
                </FormGroup>
                <FormGroup className="register-checkbox">
                    <FormControlLabel
                        name="isCompany"
                        onChange={handleChange}
                        label={t.companyAccount}
                        control={<Checkbox color="primary" checked={values.isCompany} />}
                    />
                </FormGroup>
            </div>
            {values.isCompany ? (
                <>
                    <div className="register-input-field">
                        <TextField
                            fullWidth
                            name="companyName"
                            variant="outlined"
                            onBlur={handleBlur}
                            label={t.companyName}
                            onChange={handleChange}
                            value={values.companyName}
                        />
                        <FormikError name="companyName" errors={errors} touched={touched} />
                    </div>
                    <div className="register-input-field">
                        <TextField
                            fullWidth
                            name="companyId"
                            variant="outlined"
                            onBlur={handleBlur}
                            label={t.companyId}
                            onChange={handleChange}
                            value={values.companyId}
                        />
                        <FormikError name="companyId" errors={errors} touched={touched} />
                    </div>
                    <div className="register-input-field">
                        <TextField
                            fullWidth
                            variant="outlined"
                            name="companyOwner"
                            onBlur={handleBlur}
                            label={t.companyOwner}
                            onChange={handleChange}
                            value={values.companyOwner}
                        />
                        <FormikError name="companyOwner" errors={errors} touched={touched} />
                    </div>
                    <div className="register-input-field">
                        <TextField
                            fullWidth
                            variant="outlined"
                            onBlur={handleBlur}
                            name="companyAddress"
                            onChange={handleChange}
                            label={t.companyAddress}
                            value={values.companyAddress}
                        />
                        <FormikError name="companyAddress" errors={errors} touched={touched} />
                    </div>
                </>
            ) : (
                <></>
            )}
            <ReCAPTCHA
                onChange={handleCaptchaChange}
                sitekey={texts.captchaSiteKey}
                onExpired={handleCaptchaExpired}
            />

            <div className='submit-btn-wrapper'>
                <Button size="large" type="submit" color="secondary" variant="contained" disabled={!values.agreeWithTC || !values.captchaToken}>
                    {t.buttonLabel}
                </Button>
            </div>
        </form>
    )
}
