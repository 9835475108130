import { connect, useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { Formik } from 'formik'

import { i18nContacts } from '../i18n'
import { GlobalState } from '../../../data/store'
import { texts } from '../../../utils/i18n/texts'
import { messages } from '../../../utils/i18n/messages'
import { ContactsPageMobileForm } from './ContactsPageMobileForm'
import { sendEmailRequest } from '../../../data/actions/usersActions'
import { useTranslate, useTranslateSingle } from '../../../hooks/translate'
import { showNotification } from '../../../data/actions/notificationActions'
import { RegisterOrderBanner } from '../../../components/RegisterOrderBanner'

import whatsappIcon from '../../../assets/whatsapp.png'
import telegramIcon from '../../../assets/telegram.png'
import viberIcon from '../../../assets/viber.png'

import './styles.css'

export function ContactsPageMobileComponent(props: any) {
    const t = useTranslate(i18nContacts)

    const bgAddress = useTranslateSingle(texts.bgAddress)
    const completeCaptcha = useTranslateSingle(messages.completeCaptcha)

    const { firstName, lastName, email: userEmail, isLogged, phoneNumber } = useSelector((state: GlobalState) => state.users.loggedIn)

    const name = isLogged ? `${firstName} ${lastName}` : ''
    const email = isLogged ? userEmail : ''
    const phone = isLogged ? phoneNumber : ''

    return (
        <div className="contacts-container-mobile">
            <div className="top-contents">
                <div className="top">
                    <Typography variant="h5" className="top-header">
                        {t.leftTitle}
                    </Typography>
                    <Typography variant="body1">
                        <strong>{t.bgAddressLabel}:</strong> {bgAddress}
                    </Typography>
                    <Typography variant="body1">
                        <strong>{t.itAddressLabel}:</strong> {texts.itAddress}
                    </Typography>
                    <div className="phones">
                        <Typography variant="body1" className='phone-info'>
                            <span className='inline-block-span'>
                                {t.bgPhoneLabel}:
                            </span>
                            <a href={`tel:${texts.phoneBG}`} className='underline'>
                                {texts.phoneBG}
                            </a>
                            <span className='inline-block-span'>
                                <a target='__blank' href={`https://wa.me/${texts.phoneBG.split(' ').join('')}`}>
                                    <img className='contact-icon' src={whatsappIcon} alt='whatsapp-icon' />
                                </a>
                                <a target='__blank' href={`https://t.me/${texts.phoneBG.split(' ').join('')}`}>
                                    <img className='contact-icon' src={telegramIcon} alt='telegram-icon' />
                                </a>
                                <a target='__blank' href={`viber://chat?number=${texts.phoneBG.split(' ').join('')}`}>
                                    <img className='contact-icon' src={viberIcon} alt='viber-icon' />
                                </a>
                            </span>
                        </Typography>
                        <Typography variant="body1" className='phone-info'>
                            <span className='inline-block-span'>
                                {t.itPhoneLabel}:
                            </span>
                            <a href={`tel:${texts.phoneIT}`} className='underline'>
                                {texts.phoneIT}
                            </a>
                            <span className='inline-block-span'>
                                <a target='__blank' href={`https://wa.me/${texts.phoneIT.split(' ').join('')}`}>
                                    <img className='contact-icon' src={whatsappIcon} alt='whatsapp-icon' />
                                </a>
                                <a target='__blank' href={`https://t.me/${texts.phoneIT.split(' ').join('')}`}>
                                    <img className='contact-icon' src={telegramIcon} alt='telegram-icon' />
                                </a>
                                <a target='__blank' href={`viber://chat?number=${texts.phoneIT.split(' ').join('')}`}>
                                    <img className='contact-icon' src={viberIcon} alt='viber-icon' />
                                </a>
                            </span>
                        </Typography>
                    </div>
                    <Typography variant="body1">
                        {t.emailLabel}:
                        <a href={`mailto: ${texts.email}`} className='underline'>
                            {texts.email}
                        </a>
                    </Typography>
                </div>
                <div className="bottom">
                    <Typography variant="h5" className="bottom-header">
                        {t.rightTitle}
                    </Typography>
                    <Formik
                        initialValues={{
                            name,
                            email,
                            phone,
                            text: '',
                        }}
                        onSubmit={async (values: any, { setSubmitting, resetForm }) => {
                            if (!values.captchaToken) {
                                props.showNotification(completeCaptcha, 'error')
                                return
                            }

                            setSubmitting(true)
                            await props.sendEmailRequest(values)
                            resetForm()
                        }}
                    >
                        {(props: any) => <ContactsPageMobileForm {...props} />}
                    </Formik>
                </div>
            </div>
            <RegisterOrderBanner />
        </div>
    )
}

const mapDispatchToProps = { sendEmailRequest, showNotification }

export const ContactsPageMobile = connect(null, mapDispatchToProps)(ContactsPageMobileComponent)
