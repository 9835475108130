import { IEcontOffice } from "../../data/reducers/econtReducer"
import { User, __defaultEcontDeliveryAddress } from "../../data/reducers/usersReducer"
import { sendReactGAEvent } from "../../utils/ReactGAEvent"
import {
    validateEmail,
    validateNonEmpty,
    validateMaxLength,
    validateDateFormat,
    validatePackageItems,
    validateNoFutureDate,
    validatePositiveNumers,
    validateNotOlderThanDate,
} from "../../utils/formik/validations"

export function handleValidateForm(values: any) {
    const d = new Date()
    d.setMonth(d.getMonth() - 3)
    const _3monthsAgo = d.getTime()

    const validateField = values.deliverTo === 'office' ? 'officeDeliveryAddress' : 'deliveryAddress'

    return {
        ...validatePackageItems(values, 'packages'),
        ...validateEmail(values, ['email']),
        ...validateNonEmpty(values, [
            'referenceNumberOrInvoice',
            'itDeliveryCompany',
            'deliveryCompany',
            'trackingNumber',
            'receiverPhone',
            'description',
            validateField,
            'siteOrigin',
            'receiver',
        ]),
        ...validateMaxLength(values, 50, [
            'referenceNumberOrInvoice',
            'deliveryCompany',
            'transactionDate',
            'receiverPhone',
            'siteOrigin',
            'receiver',
            'valueEur',
        ]),
        ...validateNotOlderThanDate(values, _3monthsAgo, '3 месеца', '3 months', ['transactionDate']),
        ...validateNoFutureDate(values, ['transactionDate']),
        ...validateMaxLength(values, 100, ['description']),
        ...validatePositiveNumers(values, ['valueEur']),
        ...validateDateFormat(values, ['transactionDate']),
    }
}

export function getRegisterOrderFormInitialValues(user: User, offices: IEcontOffice[]): any {
    const defaultAddress = user.address ? `${user.country}, ${user.city}, ${user.address}` : ''

    return {
        siteOrigin: '',
        description: '',
        packages: [
            {
                weight: '1',
                sizes: '20x25x30',
                processed: false,
            },
        ],
        deliverTo: 'office',
        referenceNumberOrInvoice: '',
        transactionDate: '',
        valueEur: '',
        trackingNumber: '',
        itDeliveryCompany: '',
        deliveryCompany: '',
        agreeWithTC: false,
        email: user.email || '',
        comment: '',
        captchaToken: '',
        receiverPhone: user.phoneNumber,
        receiver: `${user.firstName} ${user.lastName}`,
        deliveryAddress: defaultAddress,
        econtOfficeDeliveryAddress: user.defaultEcontOfficeDeliveryAddress || __defaultEcontDeliveryAddress,
    }
}

export async function handleSubmitForm(values: any, setSubmitting: any, resetForm: any, registerOrder: any, userId: string, role: string) {
    setSubmitting(true)

    if (values.deliveryCompany === 'econt' && values.deliverTo === 'office') {
        values.deliveryAddress = values.econtOfficeDeliveryAddress.en
    }

    if (values.packages.length > 1) {
        let weight = 0
        for (const pckg of values.packages) {
            weight += +pckg.weight
        }
        values.totalWeight = +weight.toFixed(2)
    } else {
        values.totalWeight = +values.packages[0].weight
    }

    const response = await registerOrder({ userId, userRole: role, ...values })
    if (response.type?.includes('fulfilled')) {
        sendReactGAEvent('orders', 'register', 'desktop')

        resetForm()
    }
}
