import { useState } from "react"
import { connect } from "react-redux"
import ReCAPTCHA from "react-google-recaptcha"
import { Button, TextField } from "@mui/material"

import { texts } from "../../utils/i18n/texts"
import { showNotification } from "../../data/actions/notificationActions"

export function CaptchaComponent(props: any) {
    const [token, setToken] = useState('')

    const handleCaptchaChange = (token: any) => setToken(token)
    const handleCaptchaExpired = () => setToken('')

    const copyToClipboard = (ev: any) => {
        ev.stopPropagation()
        navigator.clipboard.writeText(token)
        props.showNotification('Copied to clipboard', 'success')
    }

    return (
        <div>
            <ReCAPTCHA
                onChange={handleCaptchaChange}
                sitekey={texts.captchaSiteKey}
                onExpired={handleCaptchaExpired}
            />
            <TextField
                disabled
                fullWidth
                value={token}
                label={'TOKEN'}
                variant="outlined"
            />
            <Button
                fullWidth
                size='large'
                color='secondary'
                variant='contained'
                onClick={copyToClipboard}
            >
                Copy token
            </Button>
        </div>
    )
}

const mapDispatchToProps = { showNotification }

export const Captcha = connect(null, mapDispatchToProps)(CaptchaComponent)