import { saveAs } from 'file-saver'
import {
	Table,
	Packer,
	TextRun,
	TableRow,
	Document,
	TableCell,
	Paragraph,
	WidthType,
	HeadingLevel,
	AlignmentType,
	VerticalAlign,
} from 'docx'

import { Order } from '../../../data/reducers/ordersReducer'
import { deliveryCompanies } from '../../../utils/deliveryCompanies'
import { PricesSettings } from '../../../data/reducers/settingsReducer'
import { InternalSchedule } from '../../../data/reducers/internalSchedulesReducer'
import { calculateTransportationPrice, getFormattedDate } from '../../../utils/functions'

export interface SimplifiedPackage {
	weight: number
	width: number
	depth: number
	height: number
}

export interface SimplifiedOrder {
	packages: SimplifiedPackage[]
	deliveryAddress: string
	receiverName: string
	phoneNumber: string
	totalWeight: string
	price: string
}

export interface DownloadPrintFileInputInterface {
	orders: SimplifiedOrder[]
	deliveryCompany: string
	startDate: string
	endDate: string
}

const generateDocxTables = (orders: SimplifiedOrder[]): Table[] => {
	return orders.map((order: SimplifiedOrder, i: number) => {
		return new Table({
			width: {
				size: 100,
				type: WidthType.PERCENTAGE
			},
			rows: [
				new TableRow({
					children: [
						new TableCell({
							verticalAlign: VerticalAlign.BOTTOM,
							children: [new Paragraph({
								text: 'Име',
								alignment: AlignmentType.CENTER,
							})]
						}),
						new TableCell({
							verticalAlign: VerticalAlign.BOTTOM,
							children: [new Paragraph({
								text: 'Телефон',
								alignment: AlignmentType.CENTER,
							})]
						}),
						new TableCell({
							verticalAlign: VerticalAlign.BOTTOM,
							children: [new Paragraph({
								text: 'Адрес',
								alignment: AlignmentType.CENTER,
							})]
						}),
						new TableCell({
							verticalAlign: VerticalAlign.BOTTOM,
							children: [new Paragraph({
								text: 'Брой пратки',
								alignment: AlignmentType.CENTER,
							})]
						}),
						new TableCell({
							verticalAlign: VerticalAlign.BOTTOM,
							children: [new Paragraph({
								text: 'Общо тегло',
								alignment: AlignmentType.CENTER,
							})]
						}),
						new TableCell({
							verticalAlign: VerticalAlign.BOTTOM,
							children: [new Paragraph({
								text: 'За плащане',
								alignment: AlignmentType.CENTER,
							})]
						}),
					]
				}),
				new TableRow({
					children: [
						new TableCell({
							verticalAlign: VerticalAlign.CENTER,
							children: [new Paragraph({
								children: [
									new TextRun({
										size: 24,
										text: order.receiverName,
									})
								],
								alignment: AlignmentType.CENTER,
							})]
						}),
						new TableCell({
							verticalAlign: VerticalAlign.CENTER,
							children: [new Paragraph({
								children: [
									new TextRun({
										size: 24,
										text: order.phoneNumber,
									})
								],
								alignment: AlignmentType.CENTER,
							})]
						}),
						new TableCell({
							verticalAlign: VerticalAlign.CENTER,
							children: [new Paragraph({
								children: [
									new TextRun({
										size: 24,
										text: order.deliveryAddress,
									})
								],
								alignment: AlignmentType.CENTER,
							})]
						}),
						new TableCell({
							verticalAlign: VerticalAlign.CENTER,
							children: [new Paragraph({
								children: [
									new TextRun({
										size: 30,
										text: order.packages.length.toString(),
									})
								],
								alignment: AlignmentType.CENTER,
							})]
						}),
						new TableCell({
							verticalAlign: VerticalAlign.CENTER,
							children: [new Paragraph({
								children: [
									new TextRun({
										size: 24,
										text: order.totalWeight,
									})
								],
								alignment: AlignmentType.CENTER,
							})]
						}),
						new TableCell({
							verticalAlign: VerticalAlign.CENTER,
							children: [new Paragraph({
								children: [
									new TextRun({
										text: order.price,
										bold: true,
										color: 'eb4034',
										size: 30,
									})
								],
								alignment: AlignmentType.CENTER,
							})]
						}),
					]
				}),
				new TableRow({
					children: [
						new TableCell({ verticalAlign: VerticalAlign.CENTER, children: [new Paragraph({ text: ' ', alignment: AlignmentType.CENTER })] }),
						new TableCell({ verticalAlign: VerticalAlign.CENTER, children: [new Paragraph({ text: ' ', alignment: AlignmentType.CENTER })] }),
						new TableCell({ verticalAlign: VerticalAlign.CENTER, children: [new Paragraph({ text: ' ', alignment: AlignmentType.CENTER })] }),
						new TableCell({ verticalAlign: VerticalAlign.CENTER, children: [new Paragraph({ text: ' ', alignment: AlignmentType.CENTER })] }),
						new TableCell({ verticalAlign: VerticalAlign.CENTER, children: [new Paragraph({ text: ' ', alignment: AlignmentType.CENTER })] }),
						new TableCell({ verticalAlign: VerticalAlign.CENTER, children: [new Paragraph({ text: ' ', alignment: AlignmentType.CENTER })] }),
					]
				}),
			]
		})
	})
}

export function downloadPrintFile(inputData: DownloadPrintFileInputInterface) {
	const { orders, deliveryCompany, startDate, endDate } = inputData

	const header = `${deliveryCompany}, ${startDate} - ${endDate}`

	const ordersTables = generateDocxTables(orders)
	const docxObj = new Document({
		sections: [
			{
				children: [
					new Paragraph({
						children: [
							new TextRun({
								text: header,
								allCaps: true
							})
						],
						heading: HeadingLevel.HEADING_1,
						alignment: AlignmentType.CENTER,
					}),
					new Paragraph({}),
				]
			},
			{ children: ordersTables }
		]
	})

	saveDocumentToFile(docxObj, `${header}.docx`)
}

export function mapToDownloadPrintFileInputData(schedule: InternalSchedule, allOrders: Order[], priceSettings: PricesSettings): DownloadPrintFileInputInterface {
	const ordersObjs = allOrders.filter(x => schedule.orders.includes(x._id))

	const tuttoPost = deliveryCompanies[0].value

	const getFormattedPackages = (order: Order) => {
		return order.packages.map((pckg: any) => {
			const [width, height, depth] = pckg.sizes.split('x').map((x: string) => +x)
			return { width, height, depth, weight: pckg.weight }
		})
	}

	const getOrderTotalWeight = (packages: Object[]): string => {
		let total = 0
		packages.forEach((pckg: any) => {
			total += pckg.weight
		})
		return `${Number(total).toFixed(1)} кг.`
	}

	const getOrderPrice = (order: Order, packages: Object[]): string => {
		const price = calculateTransportationPrice(packages, priceSettings, order.deliveryCompany === tuttoPost, !!order.unregistered)
		return `${price} лв.`
	}

	const simplifiedOrders = ordersObjs.map((order: Order, i: number) => {
		const packages = getFormattedPackages(order)
		return {
			deliveryAddress: order.deliveryAddress,
			phoneNumber: order.receiverPhone,
			receiverName: order.receiver,
			totalWeight: getOrderTotalWeight(packages),
			packages,
			price: getOrderPrice(order, packages)
		} as SimplifiedOrder
	})

	return {
		startDate: getFormattedDate(true, true, true, false, schedule.startDate),
		endDate: getFormattedDate(true, true, true, false, schedule.endDate),
		deliveryCompany: schedule.deliveryCompany,
		orders: simplifiedOrders
	} as DownloadPrintFileInputInterface
}

function saveDocumentToFile(doc: any, fileName: string) {
	const mimeType = 'application/vnd.openxmlformats-officedocument.wordprocessingml.document'
	Packer.toBlob(doc).then((blob) => {
		const docblob = blob.slice(0, blob.size, mimeType)
		saveAs(docblob, fileName)
	})
}