import { Button, TextField } from '@mui/material'
import { Fade } from 'react-awesome-reveal'
import ReCAPTCHA from 'react-google-recaptcha'

import { i18nContacts } from '../i18n'
import { texts } from '../../../utils/i18n/texts'
import { easeTimes } from '../../../utils/easeTimes'
import { useTranslate } from '../../../hooks/translate'
import { FormikError } from '../../../utils/formik/FormikError'

export function ContactsPageDesktopForm(props: any): JSX.Element {
    const { handleSubmit, handleChange, values, errors, touched, handleBlur, setFieldValue } = props

    const t = useTranslate(i18nContacts)

    const handleCaptchaChange = (token: any) => setFieldValue('captchaToken', token)
    const handleCaptchaExpired = () => setFieldValue('captchaToken', '')

    return (
        <form onSubmit={handleSubmit} className="contacts-form">
            <Fade cascade triggerOnce damping={0.3} duration={easeTimes.slow} direction="up">
                <div className="contacts-input-field">
                    <TextField
                        fullWidth
                        name="name"
                        size="small"
                        label={t.names}
                        variant="outlined"
                        value={values.name}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    <FormikError name="name" errors={errors} touched={touched} />
                </div>
                <div className="contacts-input-field">
                    <TextField
                        fullWidth
                        size="small"
                        name="email"
                        label={t.email}
                        variant="outlined"
                        value={values.email}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    <FormikError name="email" errors={errors} touched={touched} />
                </div>
                <div className="contacts-input-field">
                    <TextField
                        fullWidth
                        size="small"
                        name="phone"
                        label={t.phone}
                        variant="outlined"
                        value={values.phone}
                        onBlur={handleBlur}
                        onChange={handleChange}
                    />
                    <FormikError name="phone" errors={errors} touched={touched} />
                </div>
                <div className="contacts-input-field">
                    <TextField
                        rows={4}
                        fullWidth
                        multiline
                        name="text"
                        size="small"
                        label={t.message}
                        variant="outlined"
                        onBlur={handleBlur}
                        value={values.text}
                        onChange={handleChange}
                    />
                    <FormikError name="text" errors={errors} touched={touched} />
                </div>
                <div className='captcha-wrapper'>
                    <ReCAPTCHA
                        onChange={handleCaptchaChange}
                        sitekey={texts.captchaSiteKey}
                        onExpired={handleCaptchaExpired}
                    />
                </div>

                <div className="submit-button-wrapper">
                    <Button type="submit" color="secondary" size="large" variant="contained" disabled={!values.captchaToken}>
                        {t.buttonLabel}
                    </Button>
                </div>
            </Fade>
        </form>
    )
}
