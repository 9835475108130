import { Formik } from 'formik'
import { connect, useSelector } from 'react-redux'
import { Typography } from '@mui/material'
import { Fade } from 'react-awesome-reveal'

import { i18nContacts } from '../i18n'
import { GlobalState } from '../../../data/store'
import { texts } from '../../../utils/i18n/texts'
import { easeTimes } from '../../../utils/easeTimes'
import { messages } from '../../../utils/i18n/messages'
import { ContactsPageDesktopForm } from './ContactsPageDesktopForm'
import { sendEmailRequest } from '../../../data/actions/usersActions'
import { useTranslate, useTranslateSingle } from '../../../hooks/translate'
import { showNotification } from '../../../data/actions/notificationActions'
import { RegisterOrderBanner } from '../../../components/RegisterOrderBanner'
import { validateEmail, validateNonEmpty } from '../../../utils/formik/validations'

import whatsappIcon from '../../../assets/whatsapp.png'
import telegramIcon from '../../../assets/telegram.png'
import viberIcon from '../../../assets/viber.png'

import './styles.css'

export function ContactsPageDesktopComponent(props: any) {
    const t = useTranslate(i18nContacts)

    const bgAddress = useTranslateSingle(texts.bgAddress)
    const completeCaptcha = useTranslateSingle(messages.completeCaptcha)

    const { firstName, lastName, email: userEmail, isLogged, phoneNumber } = useSelector((state: GlobalState) => state.users.loggedIn)

    const name = isLogged ? `${firstName} ${lastName}` : ''
    const email = isLogged ? userEmail : ''
    const phone = isLogged ? phoneNumber : ''

    return (
        <div className="contacts-container">
            <div className="top-contents">
                <div className="left">
                    <Fade cascade triggerOnce damping={0.3} duration={easeTimes.slow} direction="up">
                        <Typography variant="h4" className="left-header">
                            {t.leftTitle}
                        </Typography>
                        <Typography variant="body1">
                            <strong>{t.bgAddressLabel}:</strong> {bgAddress}
                        </Typography>
                        <Typography variant="body1">
                            <strong>{t.itAddressLabel}:</strong> {texts.itAddress}
                        </Typography>
                        <div className="phones">
                            <Typography variant="body1" className='phone-info'>
                                {t.bgPhoneLabel}:{' '}
                                <a href={`tel:${texts.phoneBG}`} className='underline'>
                                    {texts.phoneBG}
                                </a>
                                <a target='__blank' href={`https://wa.me/${texts.phoneBG.split(' ').join('')}`}>
                                    <img className='contact-icon' src={whatsappIcon} alt='whatsapp-icon' />
                                </a>
                                <a target='__blank' href={`https://t.me/${texts.phoneBG.split(' ').join('')}`}>
                                    <img className='contact-icon' src={telegramIcon} alt='telegram-icon' />
                                </a>
                                <a target='__blank' href={`viber://chat?number=${texts.phoneBG.split(' ').join('')}`}>
                                    <img className='contact-icon' src={viberIcon} alt='viber-icon' />
                                </a>
                            </Typography>
                            <Typography variant="body1" className='phone-info'>
                                {t.itPhoneLabel}:{' '}
                                <a href={`tel:${texts.phoneIT}`} className='underline'>
                                    {texts.phoneIT}
                                </a>
                                <a target='__blank' href={`https://wa.me/${texts.phoneIT.split(' ').join('')}`}>
                                    <img className='contact-icon' src={whatsappIcon} alt='whatsapp-icon' />
                                </a>
                                <a target='__blank' href={`https://t.me/${texts.phoneIT.split(' ').join('')}`}>
                                    <img className='contact-icon' src={telegramIcon} alt='telegram-icon' />
                                </a>
                                <a target='__blank' href={`viber://chat?number=${texts.phoneIT.split(' ').join('')}`}>
                                    <img className='contact-icon' src={viberIcon} alt='viber-icon' />
                                </a>
                            </Typography>
                        </div>
                        <Typography variant="body1">
                            {t.emailLabel}:{' '}
                            <a href={`mailto: ${texts.email}`} className='underline'>
                                {texts.email}
                            </a>
                        </Typography>
                    </Fade>
                </div>
                <div className="right">
                    <Typography variant="h4" className="right-header">
                        {t.rightTitle}
                    </Typography>
                    <Formik
                        validate={(values) => {
                            return {
                                ...validateNonEmpty(values, ['name', 'email', 'phone', 'text']),
                                ...validateEmail(values, ['email']),
                            }
                        }}
                        initialValues={{
                            name,
                            email,
                            phone,
                            text: '',
                            captchaToken: ''
                        }}
                        onSubmit={async (values: any, { setSubmitting, resetForm }) => {
                            if (!values.captchaToken) {
                                props.showNotification(completeCaptcha, 'error')
                                return
                            }

                            setSubmitting(true)
                            await props.sendEmailRequest(values)
                            resetForm()
                        }}
                    >
                        {(props: any) => <ContactsPageDesktopForm {...props} />}
                    </Formik>
                </div>
            </div>
            <RegisterOrderBanner />
        </div>
    )
}

const mapDispatchToProps = { sendEmailRequest, showNotification }

export const ContactsPageDesktop = connect(null, mapDispatchToProps)(ContactsPageDesktopComponent)
